// Action fired on user login
export const LOGIN = "LOGIN";
// Action fired on user logout
export const LOGOUT = "LOGOUT";
// Action fired on ajax request start/end/fail or whatever..
export const REQUEST_END = "REQUEST_END";
// Action fired on ajax request start/end/fail or whatever..
export const REQUEST_START = "REQUEST_START";
// Action fired on ajax request start/end/fail or whatever..
export const CALLS_UPDATE = "CALLS_UPDATE";

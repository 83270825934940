import React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Logger from '../classes/Logger.js';
import Api from '../classes/Api.js';

import '../styles/MetadataModal.css';

class MetadataModal extends React.Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        name: 'MetadataModal'
    }

    /**
     * Component constructor
     * @param {Object} props [Component props]
     */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: false,
            loading: true,
            token: null
        }
    }

    /**
     * Open modal and start downloading data
     * @param  Object token     The token object
     * @return void
     */
    open = (token) => {
        // Set state with event targe value
        this.setState({
            visible: true,
            token: token,
            loading: true
        });

        // Download metadata
        this._downloadMetadata(token.id);
    }

    /**
     * Close action by clicking on close button
     * @return void
     */
    _close = () => {
        // Set state with event targe value
        this.setState({
            visible: false,
            loading: true,
            token: null,
        });
    }

    /**
     * Download metadata from token opened
     * @param  integer id       The id of the token
     * @return Promise           The Axios promise
     */
    _downloadMetadata = (id) => {
        // Get id form param or from state
        id = id || (this.state.token ? this.state.token.id : null);
        // Compose filters
        const filters = {token_id: id};
        // Call API method
        return Api.getTokenMetadata(filters).then(
            // Manage response
            response => {
                // Log in debug the call
                Logger.write(
                    'MetadataModal@_downloadMetadata -> success.', 0, response
                );
                // Get data from response
                let data = response.data;
                // Set plot data into store
                this.setState({
                    metadata: data.data,
                    loading: false
                });
                // Return plot data
                return data;
            }
        ).catch(
            err => {
                // Log in debug the call
                Logger.write('MetadataModal@_downloadMetadata -> error.', 3, err);
                // Set empty data
                this.setState({
                    metadata: null,
                    loading: false
                });
                // Return empty array
                return [];
            }
        );
    }

    /**
     * Compose modal body
     * @return HTML      The body of the modal
     */
    _composeBody = () => {
        // Take vars from state
        const { loading, metadata } = this.state;

        // If is loading, return loading message
        if (loading) return "Loading metadata, please wait...";

        // If metadata is null, means error
        if (!metadata) return "An error occurred while loading metadata,"
            + " please try again later";

        // If no metadata was found, return message
        if (metadata.length === 0) return "No metadata for current token";

        // Compose metadatas
        return metadata.map(meta => <span
                className="badge badge-light metadata-container"
                key={meta['id']} >
                <b>{meta['key'].toUpperCase()}: </b>
                <span className="metadata-description">{meta['value']}</span>
            </span>
        );
    }

    /**
     * Render component
     * @return {} []
     */
    render() {
        // Take visible from state
        const { visible, token } = this.state;
        // Compose body
        const body = this._composeBody();
        // Return render
        return (
            <Modal
                show={visible}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <b>Metadata of token {token ? token.string : "-"}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="metadata-box"> {body} </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary mx-1"
                        size="sm"
                        onClick={this._close}> Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

// Export default component to be accessible in other components
export default MetadataModal;

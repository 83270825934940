import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import Layout from './components/pages/home/Layout.js';
import Login from './components/pages/login/Login.js';
import Utility from './components/classes/Utility.js';

import './components/styles/App.css';

// Import custom theme
require('./lib/css/elements.css')


// Create a custom route that check if is authenticated and location is login
const LoginRoute = ({ component: Component, ...rest }) => {
    // Add your own authentication on the below line.
    const isLoggedIn = rest.isAuthenticated && localStorage.getItem('token');

    return (
        <Route {...rest}
            render={props =>
                isLoggedIn ? (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                ) : (
                    <Component {...props} />
                )
            }
            />
    )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
    // Add your own authentication on the below line.
    const isLoggedIn = rest.isAuthenticated;

    return (
        <Route {...rest}
            render={ props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
            />
    )
}

class App extends Component {

        /**
         * Load token from Redux store and return if logged or not
         * @return object       The view data taken from changeView redux reducer
         */
        _isLoggedIn = () => {
            // If exist viewData AND exist viewData.data, AND data si not null
            return (this.props.token && !Utility.isEmpty(this.props.token))
                // Return token
                ? this.props.token
                // Otherwise return null
                : null;
        }

        render() {
            // Get if is logged in or not
            let isLogged = this._isLoggedIn();
            // Return the view initialized
            return (
                <div className="App">
                    <BrowserRouter>
                        <Switch>
                            <LoginRoute path="/login" component={Login} isAuthenticated={isLogged} />
                            <PrivateRoute path="/" component={Layout} isAuthenticated={isLogged} />
                        </Switch>
                    </BrowserRouter>
                </div>
            );
        }
}


// Take the redux store
const mapStateToProps = state => {
    // From redux store, take only login action
    const token = state.login.token;
    // Return the token of login action redux and set it to component props
    return { token };
};

export default connect(mapStateToProps)(App);

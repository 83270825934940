import React from 'react'
import { connect } from 'react-redux'

import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

import EPanel from '../../../lib/components/panel';

import { login } from '../../../redux/actions'
import Config from '../../classes/Config.js';
import Logger from '../../classes/Logger.js';
import Api from '../../classes/Api.js';


import '../../styles/Login.css';


class Login extends React.Component {

    /**
    * Set default props
    * @type
    */
    static defaultProps = {
        name: 'Login',
        className: 'login-form'
    }

    /**
    * Constructor method
    * @param object props object props
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            inputUsername: "",
            inputPassword: "",
            errorUsername: null,
            errorPassword: null,
        }
    }

    /**
     * Call API and do login
     * @return {}   [Do login and redirect if success]
     */
    doLogin = () => {
        // Take email
        let errorUsername = this.state.inputUsername.trim().length < 1 ? "Inserisci un username valido" : null;
        let errorPassword = this.state.inputPassword.trim().length < 1 ? "Inserisci la password" : null;

        // Save the error
        this.setState({
            errorUsername: errorUsername,
            errorPassword: errorPassword
        });

        // If an error is defined
        if (errorUsername || errorPassword) {
            // Return false
            return false;
        }

        // Compose credentials
        let credentials = {
            'username': this.state.inputUsername,
            'password': this.state.inputPassword
        }

        // Save this scope
        let me = this;

        // Call get task from API
        Api.login(credentials).then(
            // Manage success
            (response) => {
                // Log in debug the call
                Logger.write('Login@doLogin -> success.', 0, response);
                // Get user from response
                let user = response.data.user;
                // WARNING: // DEBUG: // TODO: ONLY FOR debug, REMOVE ME!
                // user['user_type'] = 1;
                // Dispatches action to login redux
                this.props.login({
                    'token': response.data.token,
                    'user': user
                });
            }
        )
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Login@doLogin -> error.', 3, err);
                console.log("WEE:", err.response);
                // Compose error message
                let msg = err.response
                    ? err.response.status + ": "
                        + err.response.statusText + ". With data: "
                        + JSON.stringify(err.response.data)
                    : "An error occurred. Please try again";
                // Save the error
                me.setState({errorPassword: msg});
            }
        );
    }

    render() {
        const Title = () => (
          <div className="h6 text-center w-100 mb-2">Sign In / Login</div>
        )

        return (
            <div className="container">
                <EPanel titleNode={<Title/>} className={this.props.className}>
                    <div className="form-group">
                        <Form.Control
                            type="email"
                            placeholder="name@example.com"
                            className="form-control"
                            onChange={(ev) => {this.setState({inputUsername:ev.target.value});}}
                            isInvalid={this.state.errorUsername}
                            />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errorUsername}
                        </Form.Control.Feedback>
                    </div>
                    <div className="form-group">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            onChange={(ev) => {this.setState({inputPassword:ev.target.value});}}
                            isInvalid={this.state.errorPassword}
                            />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errorPassword}
                        </Form.Control.Feedback>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <Button onClick={this.doLogin} className="btn btn-primary">Submit</Button>
                    </div>
                    <div className="form-group text-center m-0">
                        {
                            Config.get('version')
                            ? "v" + Config.get('version') + " " + Config.get('env')
                            : "File di configurazione non trovato"
                        }
                    </div>
                </EPanel>
            </div>
        );
    }

}
// Export default component to be accessible in other components
export default connect(
  null,
  { login }
)(Login);

import axios from 'axios';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

// Import redux store for data exchange
import store from '../../redux/store';
import { logout } from '../../redux/actions';
import { callsUpdate } from '../../redux/actions';

import Mock from './Mock.js';
import Config from './Config.js';
import Logger from './Logger.js';
import History from './History.js';
import Utility from './Utility.js';

// Make call to API with axios
const Api = {
    /**
     * Timeout call value, in ms
     * @type integer
     */
    timeout: Config.get('DEFAULT_API_TIMEOUT_MS', 60000),

    /**
     * Indicate if after call should enable render
     * @type {Boolean}
     */
    render: true,

    /**
     * Login an user
     * @param array     credentials     The user credentials
     * @return Promise                  Axios instance
     */
    login: function(credentials) {
        // Log in debug the call
        Logger.write('Api@login -> call start.', 0);
        // Set verb to GET
        let verb = 'POST';
        // Load the url for current call
        let url = Config.get('URL_API_LOGIN');
        // Init headers
        let headers = {};

        // If env is local, return mock data
        if (Config.get('env') === "local") return this.callMock(url);

        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, headers, credentials);
    },

    /**
     * Logout an user
     * @return Promise                  Axios instance
     */
    logout: function() {
        // Log in debug the call
        Logger.write('Api@logout -> call start.', 0);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_LOGOUT');

        // If env is local, return mock data
        if (Config.get('env') === "local") return this.callMock(url);

        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Load the list of tokens
     * @return Promise      Axios instance
     */
    getTokens: function(queryParams) {
        // Log in debug the call
        Logger.write('Api@getTokens -> call start.', 0, queryParams);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_TOKENS');

        // If env is local, return mock data
        if (Config.get('env') === "local") return this.callMock(
            url, queryParams
        );

        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, null, null, queryParams);
    },

    /**
     * Load the plot data for tokens
     * @return Promise      Axios instance
     */
    getTokensPlot: function(queryParams) {
        // Log in debug the call
        Logger.write('Api@getTokensPlot -> call start.', 0, queryParams);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_TOKENS_PLOT');

        // If env is local, return mock data
        if (Config.get('env') === "local") return this.callMock(
            url, queryParams
        );

        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, null, null, queryParams);
    },

    /**
     * Load the list of token metadata
     * @return Promise      Axios instance
     */
    getTokenMetadata: function(queryParams) {
        // Log in debug the call
        Logger.write('Api@getTokenMetadata -> call start.', 0, queryParams);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_TOKEN_METADATA');

        // If env is local, return mock data
        if (Config.get('env') === "local") return this.callMock(
            url, queryParams
        );

        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, null, null, queryParams);
    },

    /**
     * Load the list of sources
     * @return Promise      Axios instance
     */
    getSources: function(queryParams) {
        // Log in debug the call
        Logger.write('Api@getSources -> call start.', 0, queryParams);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_SOURCES');

        // If env is local, return mock data
        if (Config.get('env') === "local") return this.callMock(
            url, queryParams
        );

        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, null, null, queryParams);
    },

    /**
     * Load the list of mmos
     * @return Promise      Axios instance
     */
    getMMOs: function(queryParams) {
        // Log in debug the call
        Logger.write('Api@getMMOs -> call start.', 0, queryParams);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_MMOS');

        // If env is local, return mock data
        if (Config.get('env') === "local") return this.callMock(
            url, queryParams
        );

        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, null, null, queryParams);
    },

    /**
     * Load the list of editors
     * @return Promise      Axios instance
     */
    getEditors: function(queryParams) {
        // Log in debug the call
        Logger.write('Api@getEditors -> call start.', 0, queryParams);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_EDITORS');

        // If env is local, return mock data
        if (Config.get('env') === "local") return this.callMock(
            url, queryParams
        );

        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, null, null, queryParams);
    },

    /*
    |-------------------------------------------------------------------------|
    |                                OTHER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Compose user data object to be sent to sent as extra param to sentry
     * @return object   User object
     */
    _composeUserReportData: function() {
        // Load user
        const user = Utility.loadPersistData('user');

        // If user was not found, return
        if (!user) return null;

        // Compose data by get only certain informations
        let data = (({
            id, email, username
        }) => ({
            id, email, username
        }))(user);
        // Compose user name
        data['name'] = user.first_name + " " + user.last_name;
        // Return only certain data
        return data;
    },

    /*
    |-------------------------------------------------------------------------|
    |                        CALL THE API WITH DATA PASSED                    |
    |-------------------------------------------------------------------------|
    */

    /**
     * API Call method
     * You can manage it calling:
     * this.ajaxCall().then((response) => {}).cath((response) => {})
     * @param  string verb      The verb of call: GET/POST/PATCH
     * @param  string url       The url to be called
     * @param  object headers    The headers to be add
     * @param  object body      The body to be add
     * @param  object params    The query parameters to be add
     * @return Promise           Axios promise
     */
    callAPI: function(verb, url, headers, body, params) {
        // If headers are null, create it
        if (!headers) headers = {};

        // If inside header there are not AUTHORIZATION field and is not login
        if (!headers.hasOwnProperty('AUTHORIZATION')
            && url !== Config.get('URL_API_LOGIN')
            // Add it
        ) headers['AUTHORIZATION'] = 'Token ' + localStorage.getItem('token');

        // Log in debug the call
        Logger.write(
            'Api@callAPI -> call start.',
            0,
            [verb, url, headers, body, params]
        );

        // Create an uuid
        let uuid = Utility.uuid();
        // Dispatch the redux action callsUpdate
        store.dispatch(callsUpdate({
            uuid: uuid,
            url: url,
            method: verb,
            body: body,
            parameters: params,
            datatime: moment().valueOf(),
            instance: axios,
            type: 'REQUEST_START'
        }));

        // Compose all axios params
        let axiosParams = {
            // Set the url
            url: url,
            // Set here method GET || POST ...
            method: verb,
            // Put here headers
            headers: headers ? headers : {},
            // Put here the data body
            data: body ? body : {},
            // Put here query parameters
            params: params ? params : {},
            // Set timeout
            timeout: this.timeout
        };

        // Create a new promise
        return new Promise(
            (resolve, reject) => {
                // Make axios request and manage success
                axios(axiosParams).then(data => {
                    // Write a debug log
                    Logger.write('Api@callAPI -> call end with success.', 0);
                    // Dispatch the redux action callsUpdate
                    store.dispatch(callsUpdate({
                        uuid: uuid,
                        url: url,
                        method: verb,
                        body: body,
                        parameters: params,
                        datatime: moment().valueOf(),
                        response: data,
                        instance: axios,
                        type: 'REQUEST_END'
                    }));

                    // Resolve promise to be available a second time
                    resolve(data);
                // Catch an axios error if it is
                }).catch(e => {
                    // Write an error log
                    Logger.write('Api@callAPI -> call end with error.', 3, e);

                    // If status is 401
                    if (e && e.response && e.response.status === 401) {
                        // Logout user
                        store.dispatch(logout(
                            {'token': localStorage.getItem('token')}
                        ));
                        // Redirect to home (login)
                        return History.go('/');
                    }

                    // Init sentry id
                    let sentryId = undefined;

                    // If a dsn was setted, sentry is initialized.
                    // COMBAK: maybe sentry has some method to check if inited?
                    if (Config.get('sentry_dsn', '')) {
                        // Compose user object
                        const user = this._composeUserReportData();
                        // Set extra data to sentry
                        Sentry.setExtra("user", user);
                        // Catch sentry exception
                        sentryId = Sentry.captureException(e);
                    }

                    // Dispatch the redux action callsUpdate
                    store.dispatch(callsUpdate({
                        uuid: uuid,
                        url: url,
                        method: verb,
                        body: body,
                        parameters: params,
                        datatime: moment().valueOf(),
                        response: e,
                        instance: axios,
                        type: 'REQUEST_END',
                        sentry_id: sentryId
                    }));

                    // Close promise with error
                    reject(e);
                });
            }
        );
    },

    /**
     * Method that load mocked data
     * @param  string url   The API url that should be called
     * @return Promise      The Promise resolved, always 200.
     */
    callMock: function(url, queryParams) {
        // Write a warning
        Logger.write(
            "Mock data will be loaded for request <" + url + "> (This becuase"
            + " you set 'local' as 'env' value in .env file)", 2
        );
        // Define query
        let query = queryParams ? queryParams : {};
        // Init data
        let data = {'count': 100};
        // Init id
        let id = parseInt(Math.random(0, 100) * 100);

        // If url is login
        if (url === Config.get('URL_API_LOGIN')) {
            // Get a random user
            data['user'] = Mock.auth_user[id];
            // Set the token
            data['token'] = data['user'].password;
            // DEBUG: need to see anything, transform me into a ADMIN!
            data['user'].user_type = 1;
        }

        // If url is sources
        if (url === Config.get('URL_API_SOURCES')) {
            // Load an array of mocked editors
            data['data'] = this.searchMocks(
                Mock.source, 'name', query.name__contains
            );
        }

        // If url is editors
        if (url === Config.get('URL_API_EDITORS')) {
            // Load an array of mocked editors
            data['data'] = this.searchMocks(
                Mock.editor, 'name', query.name__contains
            );
        }

        // If url is mmos
        if (url === Config.get('URL_API_MMOS')) {
            // Load an array of mocked mmos
            data['data'] = this.searchMocks(
                Mock.mmo, 'name', query.name__contains
            );
        }

        // If url is tokens
        if (url === Config.get('URL_API_TOKENS')) {
            // Load an array of mocked tokens
            data['data'] = this.searchMocks(
                Mock.token, 'name', query.name__contains
            );
        }

        // If url is tokens plot
        if (url === Config.get('URL_API_TOKENS_PLOT')) {
            // Get only fake plot data
            data['data'] = Mock.plot();
        }

        // Init response
        let response = {};
        // Set data into response
        response['data'] = data;
        // Set call status
        response['status'] = 200;
        // Set call status text
        response['statusText'] = "OK";
        // Return the response with data resolved in a promise
        return new Promise((resolve, reject) => {
            // Wait a little bit before return
            let wait = setTimeout(() => {
                // Clear timer to avoid repeat
                clearTimeout(wait);
                // Resolve promise in a success
                resolve(response);
            // Wait 1 sec
            }, 1000);
        });
    },

    /**
     * Filter mocked data with simple filters
     * @param  array records    Array of records objects
     * @param  string key       The key of object to be filtered
     * @param  string value     The value of the object prop to be matched
     * @return array            An array of results
     */
    searchMocks: function(records, key, value) {
        // If records are null, return empty array
        if (!records) return [];

        // Init limit
        const limit = parseInt(Config.get('default_limit'));
        // Init an number for pagination
        let from = parseInt(Math.random(0, (100 - limit)) * 100);

        // If value is defined, search all record with that value
        if (value) return records.filter(s => s[key].includes(value));

        // Otherwise take only X
        return records.slice(from, (from + limit));
    }

}

export default Api

import React from 'react'

import Table from '../../partials/Table';
import Plot from '../../partials/Plot';
import Header from '../../partials/Header';
import Sidebar from '../../partials/Sidebar';
import MetadataModal from '../../partials/MetadataModal';

import Api from '../../classes/Api';
import Config from '../../classes/Config';
import Logger from '../../classes/Logger';
import Utility from '../../classes/Utility';

import '../../styles/Layout.css';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
class Layout extends React.Component {

    /**
    * Constructor method
    * @param object props object props
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            minified: false,
            tableData: [],
            plotData: {},
            filters: {
                'page': 1,
                'limit': Config.get('DEFAULT_PAGINATION_LIMIT')
            },
            total: 0,
            totalConsumed: 0
        }

    }

    componentDidMount() {
        // At initialize make a search
        this.makeSearch(this.state.filters);
    }

    /**
    * Toggle full screen value
    * @return {[type]} [description]
    */
    toggleMinified = () => {
        // Set state
        this.setState({
            // Set opposite
            minified: !this.state.minified
        })
    }

    makeSearch = async (filters) => {
        // Set is loading
        this.setState({ loading: true });

        // If filters are null
        if (!filters) filters = {};

        // Load tokens from API
        Api.getTokensPlot(filters).then(
            // Manage response
            response => {
                // Log in debug the call
                Logger.write('Layout@makeSearch -> success.', 0, response);
                // Elaborate plot data
                const data = this._elaboratePlotData(response.data);
                // Set plot data into store
                this.setState({plotData: data});
                // Return plot data
                return data;
            }
        ).catch(
            err => {
                // Log in debug the call
                Logger.write('Layout@makeSearch -> error.', 3, err);
                // Set empty data
                this.setState({plotData: {}});
                // Return empty data
                return {};
            }
        );

        // Take the filters and add limit 1 + consumed_at__gt to get only
        // consumed count
        const consumedFilters = {
            ...filters, limit:1, consumed_at__gt:'1970-01-01'
        };
        // Load consumed_at total from API
        let totalConsumed = await Api.getTokens(consumedFilters).then(
            // Manage response
            response => {
                // Log in debug the call
                Logger.write('Layout@makeSearch -> success.', 0, response);
                // Get data from response
                let data = response.data;
                // Return only the count
                return data.count;
            }
        ).catch(
            err => {
                // Log in debug the call
                Logger.write('Layout@makeSearch -> error.', 3, err);
                // Return 0
                return 0;
            }
        );

        // Load tokens from API
        return Api.getTokens(filters).then(
            // Manage response
            response => {
                // Log in debug the call
                Logger.write('Layout@makeSearch -> success.', 0, response);
                // Get data from response
                let data = response.data;
                // Store data inside store
                this.setState({
                    tableData: data.data,
                    total: data.count,
                    totalConsumed: totalConsumed,
                    filters: filters,
                    loading: false
                });
                // Return items
                return data.data;
            }
        ).catch(
            err => {
                // Log in debug the call
                Logger.write('Layout@makeSearch -> error.', 3, err);
                // Set empty array
                this.setState({
                    tableData: [],
                    total: 0,
                    totalConsumed: totalConsumed,
                    filters: filters,
                    loading: false
                });
                // Return an empty array
                return [];
            }
        );
    }

    /**
     * Method called by table to load data async
     * @param  string type       The event type of calling (pagination/filter..)
     * @param  object tableState The object with all table informations
     * @return Promise
     */
    onTableChange = (type, tableState) => {
        // Get the filters
        let filters = {...this.state.filters};
        // Override page
        filters['page'] = tableState.page;

        // If there is a sort order
        if (tableState.sortField) {
            // Change sorting value
            filters['sorting'] = tableState.sortOrder === "asc"
                // Set the order ascending
                ? tableState.sortField
                // Set order descending
                : ("-" + tableState.sortField);
        }

        // Make a search
        return this.makeSearch(filters);
    }

    /**
     * Open metadata modal
     * @param  object   token   The token to display
     * @return void
     */
    onMetadaModalOpen = (token) => {
        // Open metadata modal
        this.refs.metadata_modal.open(token);
    }

    /**
     * Elaborate API response to make Graph JS compatible data
     * @param  object data  API response data
     * @return object       GraphJS compatible object data
     */
    _elaboratePlotData = (data) => {

        // If data is not defined
        if (!data || !data.datasets || data.datasets.length === 0) return {};

        // Take datasets
        let datasets = data.datasets;
        // Get records of dataset
        let records = datasets[0].data;
        // Get all names
        let labels = records.map(r => r.name);
        // Init plot
        let plot = {};
        // Init i counter
        let i = 0;
        // Init color and counts
        let color, counts;
        // Set label for X axis
        plot['labels'] = labels;

        // Set Y values and datasets
        plot['datasets'] = datasets.map(dataset => {
            // Take the color
            color = Utility.getColor(i, true);
            // Get all counts
            counts = dataset['data'].map(r => r.count);
            // Set border color
            dataset['borderColor'] = "rgb(" + color + ", 0.9)";
            // Set background color
            dataset['backgroundColor'] = "rgb(" + color + ", 0.7)";
            // Set border width
            dataset['borderWidth'] = 2;
            // Set point radius
            dataset['pointRadius'] = 0;
            // override counts
            dataset['data'] = counts;
            // Increase counter
            i++;
            // Return edited datasers
            return dataset;
        });

        // Return formatted plot data
        return plot;
    }

    render() {
        // Take total and total consumed from state
        const {
            total, totalConsumed, minified, plotData, tableData, filters,
            loading
        } = this.state;
        // Render component
        return (
            <div className="body row m-0">

                {/* HEADER NAVBAR */}
                <div className="header col-12 p-4 bg-light">
                    <Header handleToggleMinified={this.toggleMinified} />
                </div>

                {/* SIDEBAR */}
                <div className={(minified ? " " : "col-3") + " sidebar pl-4 pr-0 bg-light"}>
                    <Sidebar
                        minified={minified}
                        handleMakeSearch={this.makeSearch}
                        loading={loading}
                        total={total}
                        totalConsumed={totalConsumed} />
                </div>

                {/* MAIN CONTENT */}
                <div className={(minified ? "col-12" : "col-9") + " container col-9 px-4 bg-light"}>

                    {/* PLOTS */}
                    <div className="col-12 mb-4">
                        <Plot data={plotData}/>
                    </div>

                    {/* MAIN CONTENT */}
                    <div className="col-md-12 mb-4">
                        <Table
                            items={tableData}
                            page={filters.page}
                            total={total}
                            limit={filters.limit}
                            handleTableChange={this.onTableChange}
                            handleMetadataModelOpen={this.onMetadaModalOpen} />
                    </div>
                </div>

                <MetadataModal ref="metadata_modal"/>
            </div>
        );
    }

}
export default Layout;

import React from 'react';
import Button from 'react-bootstrap/Button';

import AsyncSelect from 'react-select/async';

import EPanel from '../../lib/components/panel';
import EDatepickerRange from '../../lib/components/datepicker/types/range';

import Api from '../classes/Api';
import Config from '../classes/Config';
import Utility from '../classes/Utility';
import Permission from '../classes/Permission';

import '../styles/Sidebar.css';

class Sidebar extends React.Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        name: 'Sidebar'
    }

    /**
     * Component constructor
     * @param {Object} props [Component props]
     */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            minified: false,
            // Filters
            date: [],
            selectedSources: [],
            selectedMMOs: [],
            selectedEditors: []
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                            HANDLE ACTION CHANGE                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * On date change event
     * @param  {React event} event [Event on change]
     * @return void
     */
    _handleDateChange = (value) => {
        // Update state with sleected values
        this.setState({ date: value })
    }

    /**
     * On select change event
     * @param  {React event} event [Event on change]
     * @return void
     */
    _handleSourceChange = (option) => {
        // Update state with sleected values
        this.setState({ selectedSources: option })
    }

    /**
     * On select change event
     * @param  {React event} event [Event on change]
     * @return void
     */
    _handleMMOChange = (option) => {
        // Update state with sleected values
        this.setState({ selectedMMOs: option })
    }

    /**
     * On select change event
     * @param  {React event} event [Event on change]
     * @return void
     */
    _handleEditorChange = (option) => {
        // Update state with sleected values
        this.setState({ selectedEditors: option })
    }

    /**
     * On click event
     * @param  {React event} event [Event on change]
     * @return void
     */
    _handleResetClick = (ev) => {
        // Reset filters
        this.setState({
            date: [],
            selectedSources: [],
            selectedMMOs: [],
            selectedEditors: []
        });
        // Init filters
        let filters = {
            "page": 1,
            "mode": "default",
            "source__id_in": undefined,
            "editor__id_in": undefined,
            "mmo__id_in": undefined,
            "consumed_at__gt": undefined,
            "consumed_at__lt": undefined
        }
        // Make search
        this.props.handleMakeSearch(filters);
    }

    /**
     * On click event
     * @param  {React event} event  Event onClick
     * @return void
     */
    _handleApplyClick = (ev) => {
        // Reset filters
        let filters = this._composeQueryFilters();
        // Make search
        this.props.handleMakeSearch(filters);
    }

    /**
     * Define the remote async option for source
     * @param  string input     String value input from select component
     * @return Promise          The promise of remote fetch
     */
    _asyncSourceOption = (input) => {
        // Compose query params
        let queryParams = {'page': 1, 'limit': 100, 'name__contains': input};
        // Return the request
        return Api.getSources(queryParams)
            // If success return the data array
            .then(response => response.data.data)
            // If error return an empty array
            .catch(err => []);
    }

    /**
     * Define the remote async option for editor
     * @param  string input     String value input from select component
     * @return Promise          The promise of remote fetch
     */
    _asyncEditorOption = (input) => {
        // Compose query params
        let queryParams = {'page': 1, 'limit': 100, 'name__contains': input};
        // Return the request
        return Api.getEditors(queryParams)
            // If success return the data array
            .then(response => response.data.data)
            // If error return an empty array
            .catch(err => []);
    }

    /**
     * Define the remote async option for mmo
     * @param  string input     String value input from select component
     * @return Promise          The promise of remote fetch
     */
    _asyncMMOOption = (input) => {
        // Compose query params
        let queryParams = {'page': 1, 'limit': 100, 'name__contains': input};
        // Return the request
        return Api.getMMOs(queryParams)
            // If success return the data array
            .then(response => response.data.data)
            // If error return an empty array
            .catch(err => []);
    }

    /**
     * Load sidebar filters and compose correct query filters for API
     * @return object   Filters to be sent to API
     */
    _composeQueryFilters = () => {
        // Init filters
        let filters = {};

        // If a date filter was selected
        if (this.state.date && this.state.date.length > 0) {
            // Set greter then date formatted
            filters['consumed_at__gt'] = Utility.momentFormat(
                this.state.date[0], 'date'
            );
            // Set lower then date formatted
            filters['consumed_at__lt'] = Utility.momentFormat(
                this.state.date[1], 'date'
            );
        }

        // If almost a sources was selected
        if (this.state.selectedSources
            && this.state.selectedSources.length > 0
        ) {
            // Get an array of source ids
            filters['source__id_in'] = this.state.selectedSources.map(
                source => source.id
            // Concat it with ,
            ).join(',');
        }

        // If almost a mmos was selected
        if (this.state.selectedMMOs && this.state.selectedMMOs.length > 0) {
            // Get an array of source ids
            filters['mmo__id_in'] = this.state.selectedMMOs.map(
                mmo => mmo.id
            // Concat it with ,
            ).join(',');
        }

        // If almost a editors was selected
        if (this.state.selectedEditors
            && this.state.selectedEditors.length > 0
        ) {
            // Get an array of source ids
            filters['editor__id_in'] = this.state.selectedEditors.map(
                editor => editor.id
            // Concat it with ,
            ).join(',');
        }

        // Always start from page 1
        filters['page'] = 1;
        // Set pagination limit
        filters['limit'] = Config.get('DEFAULT_PAGINATION_LIMIT', 10);
        // Return filters formatted
        return filters;
    }


    /**
     * Render component
     * @return {} []
     */
    render() {
        // Take total and total consumed from props
        const { total, totalConsumed, minified, loading } = this.props;

        // Create the editor filter
        const MMOFilter = () => {
            // If is not editor, don't return anything
            if (!Permission.isEditor()) return <div />;

            // Return the MMO filters
            return <EPanel className="my-2" title="MMO" subtitle="Select one or more MMOs">
                <AsyncSelect
                    isMulti
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option)=> option.name}
                    value={this.state.selectedMMOs}
                    onChange={this._handleMMOChange}
                    loadOptions={this._asyncMMOOption}
                    placeholder="Click here or write something"
                    defaultOptions />
            </EPanel>;
        }

        // Create the MMO filter
        const EditorFilter = () => {
            // If is not MMO, don't return anything
            if (!Permission.isMMO()) return <div />;

            // Return the Editor filters
            return <EPanel className="my-2" title="Editor" subtitle="Select one or more Editors">
                <AsyncSelect
                    isMulti
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option)=> option.name}
                    value={this.state.selectedEditors}
                    onChange={this._handleEditorChange}
                    loadOptions={this._asyncEditorOption}
                    placeholder="Click here or write something"
                    defaultOptions />
            </EPanel>;
        }

        return (
            <div>
                <div className={minified ? "hidden" : ""} >
                    {/* DATE */}
                    <EPanel className="mb-2" title="Date Consumed" subtitle="Select a date range">
                          <EDatepickerRange value={this.state.date} onChange={this._handleDateChange} placeholder="Select range" />
                    </EPanel>

                    {/* SOURCE */}
                    <EPanel className="my-2" title="Source" subtitle="Select one or more soruces">
                        <AsyncSelect
                            isMulti
                            getOptionLabel={(option)=> option.name}
                            getOptionValue={(option)=> option.name}
                            value={this.state.selectedSources}
                            onChange={this._handleSourceChange}
                            loadOptions={this._asyncSourceOption}
                            placeholder="Click here or write something"
                            defaultOptions
                            />
                    </EPanel>

                    {/* MMO */}
                    <EditorFilter />

                    {/* EDITORS */}
                    <MMOFilter />

                    {/* TOTAL COUNTS */}
                    <EPanel className="mb-2" title="Counts" subtitle="Overview of token total counts">
                        <div className="total-count-element">
                            <span className="count-title">Total:</span>
                            <span className="count-description">{total}</span>
                        </div>
                        <div className="total-count-element">
                            <span className="count-title">Consumed:</span>
                            <span className="count-description">{totalConsumed}</span>
                        </div>
                        <div className="total-count-element">
                            <span className="count-title">Remaining:</span>
                            <span className="count-description">{total - totalConsumed}</span>
                        </div>
                    </EPanel>

                    {/* BUTTONS */}
                    <EPanel className="my-2 sidebar-button-panel" noHead={true}>
                        <Button
                            className="float-left"
                            variant="outline-secondary mx-1"
                            size="sm"
                            onClick={this._handleResetClick}
                            disabled={loading} >Reset</Button>
                        <Button
                            className="float-right"
                            variant="outline-primary mx-1"
                            size="sm"
                            onClick={this._handleApplyClick}
                            disabled={loading} >
                            {loading ? "Loading..." : "Apply"}
                        </Button>
                    </EPanel>
                </div>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default Sidebar;

// const el = document.body
// ReactDOM.render(<Sidebar name='John' />, el)

import { useEffect, useRef } from "react";
import moment from 'moment';

import Config from './Config';

/**
 * Utility methods goes here
 * @type {Object}
 */
const Utility = {
    // If you need static data, call like this.data.myprop
    data: [],

    /**
     * Generates a GUID string.
     * @returns string The generated GUID.
     * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
     * @author Slavik Meltser (slavik@meltser.info).
     * @link http://slavik.meltser.info/?p=142
     */
    uuid: function() {
        function _p8(s) {
            var p = (Math.random().toString(16)+"000000000").substr(2,8);
            return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    },

    /**
     * Search objects in deep inside object with jQuery
     * @param  {jQuery} obj     Object where search
     * @param  {String} key     Key to find
     * @param  {?} val          Value to find
     * @return {Array}          Array of results
     */
    search: function(obj, key, val) {
        var objects = [];
        for (var i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if (typeof obj[i] === 'object') {
                objects = objects.concat(this.search(obj[i], key, val));
            } else if (i === key && obj[key] === val) {
                objects.push(obj);
            }
        }
        return objects;
    },

    /**
     * Compare 2 array of object and get out the differences
     * @param  array arr1   The first array
     * @param  array arr2   The second array
     * @return array        All the differences
     */
    arrayOfObjectDiff: function(arr1, arr2, key) {
        key = key || 'id';
        // Filter elements only in A
        let onlyA = arr1.filter(
            item => !arr2.some(other => item[key] === other[key])
        );
        // Filter elements only in B
        let onlyB = arr2.filter(
            item => !arr1.some(other => item[key] === other[key])
        );
        // Made a single array with all the differences
        return  onlyA.concat(onlyB);
    },

    /**
     * Return if is an empty object
     * @param  object   obj     The object to check
     * @return boolean          True if is empty
     */
    isEmpty: function(obj) {
        // If object is null or has no keys, its empty!
        return !obj || Object.keys(obj).length === 0;
    },


    /**
     * Load a temp variable from local storage
     * @param  string name  The name of variable to load
     * @return multy        The variable
     */
    loadPersistData: function(name) {
        // If element is in local storage
        return localStorage.getItem(name)
            // Parse it to object
            ? JSON.parse(localStorage.getItem(name))
            // Otherwise turn null
            : null;
    },

    /**
     * Save a temp variable into local storage
     * @param  string name  The name of variable to load
     * @return multy        The variable
     */
    savePersistData: function(name, value) {
        // If is empty value
        if (Utility.isEmpty(value)) {
            // Delete the var
            return this._deleteTempData(name);
        }

        // Set the item inside local storage
        return localStorage.setItem(
            name,
            JSON.stringify(value)
        );
    },

    /**
     * Remove a temp variable from local storage
     * @param  string name  The name of variable to load
     * @return void
     */
    deletePersistData: function(name) {
        // Remove item from local storage
        return localStorage.removeItem(name);
    },

    /**
     * Compare the rest url of an resource and the id.
     * If url has same rource id  return true
     * EX: "https://mimesi.willie.media/api/v1/tasks/123" and 123
     * @param  string url   The RESTful url of the resource
     * @param  integer id   The integer id of an resource
     * @return boolean      True if are the same (id resource and id passed)
     */
    resourceUrlComparer: function(url, id) {
        // Get the resource id from url
        let resourceId = this.getResourceIdFromUrl(url);
        // If resource id is defined
        return resourceId
            // Return if resouce id is the same of id passed
            ? parseInt(resourceId) === parseInt(id)
            // Otherwise is false, not equals
            : false;
    },


    /**
     * Cut the last ID of an url (taking care of trailing slash) and return it
     * @param  string url   The RESTful url
     * @return string       The id (string) or undefined
     */
    getResourceIdFromUrl: function(url) {
        // If url is null, return null
        if (!url) return null;
        // Check if has slash
        let slash = url.slice(url.length -1) === "/" ? true : false;
        // Split url by "/" char
        let parts = url.split('/');
        // Take the url resource id (if has slash or not)
        return slash ? parts[parts.length-2] : parts[parts.length-1];
    },

    /*
    |-------------------------------------------------------------------------|
    |                           PRIVATE METHODS                               |
    |-------------------------------------------------------------------------|
    */

    /**
     * Get metadata from type.
     * This method is used in isMetadataMissing to avoid eslint don't make
     * func within a loop
     * @param  object metadata      The metadata raw data
     * @param  string type          The type of metadata to find
     * @return object               The metadata found
     */
    _findMetaFromType: function(metadata, type) {
        // Return the metadata
        return metadata.find(meta => meta.type === type);
    },

    /**
    * Performs equality by iterating through keys on an object and returning false
    * when any key has values which are not strictly equal between the arguments.
    * Returns true when the values of all keys are strictly equal.
    */
    shallowEqual: function(objA, objB) {
        if (objA === objB) {
            return true;
        }

        if (typeof objA !== 'object' || objA === null ||
        typeof objB !== 'object' || objB === null) {
            return false;
        }

        var keysA = Object.keys(objA);
        var keysB = Object.keys(objB);

        if (keysA.length !== keysB.length) {
            return false;
        }

        // Test for A's keys different from B.
        var bHasOwnProperty = hasOwnProperty.bind(objB);
        for (var i = 0; i < keysA.length; i++) {
            if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
                return false;
            }
        }

        return true;
    },

    shallowCompare: function(instance, nextProps, nextState) {
        return (
            !this.shallowEqual(instance.props, nextProps) ||
            !this.shallowEqual(instance.state, nextState)
        );
    },

    /**
     * Format the input value and return a moment object, unix timestamp,
     * string date time or human readable date value
     * @param  string value         The value to be formatted
     * @param  string format        The format to be apply
     * @param  string inputFormat   The input format to parse
     * @return multy                The value formatted
     */
    momentFormat: function(value, format, inputFormat) {
        // If is undefined
        if (!value) return undefined;

        // Check if we have a format to be parsed
        inputFormat = inputFormat || 'YYYY-MM-DD HH:mm:ss.SSS';
        // Init object
        let obj = null;

        // If value is string
        typeof value === "string"
            // Create a moment object from string
            ? obj = moment(value, inputFormat)
            // Otherwise create a moment object from date
            : obj = moment(value);

        // If format is 'moment', return moment object
        if (format === 'moment') return obj.clone();
        // If format is 'raw', return the default value without formatting
        if (format === 'raw') return this.raw.start_datetime;
        // If format is 'time', return only time value string
        if (format === 'time') return obj.format('HH:mm:ss');
        // If format is 'api', return value string formatting for API.
        if (format === 'api') return obj.format('YYYY-MM-DDTHH:mm:ss.SSS');
        // If format is 'human date', return only date value string
        if (format === 'date') return obj.format('YYYY-MM-DD');
        // If format is 'human', return the default human value string
        if (format === 'human') return obj.format('DD/MM/YYYY HH:mm:ss');
        // If format is 'human_short', return the default human short value
        if (format === 'human_short') return obj.format('DD/MM HH:mm:ss');
        // If format is 'human date', return only date value string
        if (format === 'human_date') return obj.format('DD/MM/YYYY');
        // If format is 'date_short', return only date short value string
        if (format === 'human_date_short') return obj.format('DD/MM');
        // Otherwise return moment unix datetime as default
        return obj.valueOf();
    },

    /**
     * Sleep for and wait
     * @param  integer ms   The number of ms to wait
     * @return Promise      The promise
     */
    sleep: function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    /**
     * Return the hexa color form the id of palette specified
     * @param  integer id   The Id (position) of the color to be returned
     * @param  boolean rgb  Return the rgb value or the hexa value?
     * @return string       The string hexa color code
     */
    getColor: function(id, rgb) {
        // Load the color palette
        let palette = Config.get('DEFAULT_COLOR_PALETTE', undefined);
        // Get all colors
        let colors = palette ? palette.split(",") : [];
        // Return the selected
        let hex = colors[id]
            // Or, if null, take an random one
            || ('#' + Math.floor(Math.random() * 16777215).toString(16));

        // If rgb is false, return the hex value
        if (!rgb) return hex;

        // Return the RGB value
        return this.hexToRgb(hex);
    },

    /**
     * Converto Hex color to rgb
     * @return string   The rgb form "123,123,123" of the hex passed
     */
    hexToRgb: function(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        // Edit short form to default form
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });
        // Parse with regex
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        // If result is defined
        return result ? (
            parseInt(result[1], 16) + "," +
            parseInt(result[2], 16) + "," +
            parseInt(result[3], 16)
        ) : null;
    },

    /**
     * Small hook to trace updates to function components
     * @param  object props     Component props
     * @return void
     */
    useTraceUpdate: function(props) {
        const prev = useRef(props);
        useEffect(() => {
            const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
                if (prev.current[k] !== v) {
                    ps[k] = [prev.current[k], v];
                }
                return ps;
            }, {});
            if (Object.keys(changedProps).length > 0) {
                console.debug(
                    'Utility@useTraceUpdate => Changed props:', changedProps
                );
            }
            prev.current = props;
        });
    }
}


export default Utility;

import React, { Component } from 'react'

import Button from 'react-bootstrap/Button';

import EChart from '../../lib/components/chart';
import EPanel from '../../lib/components/panel';

import Utility from '../classes/Utility';

import '../styles/Plot.css';

class Plot extends Component {

    constructor(props) {
        super(props)

        this.state = {
            panelMinified: false
        }

        this.options = {
            maintainAspectRatio: false,
            legend: { display: false },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: true,
                            color: 'rgb(240, 240, 240)',
                            drawBorder: false
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                // Return only integer not decimal
                                return value
                                    ? Utility.momentFormat(
                                        value,
                                        'human_date_short',
                                        'MM/DD/YYYY'
                                    ) : "";
                            }
                        }
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: true,
                            color: 'rgb(240, 240, 240)',
                            zeroLineColor: 'rgb(240, 240, 240)',
                            drawTicks: false
                        },
                        ticks: {
                            padding: 10,
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                // Return only integer not decimal
                                return value % 1 === 0 ? value : "";
                            }
                        }
                    }
                ]
            }
        }
    }

    _handleMinifiedPanelClick = () => {
        // Invert value of panel minified
        this.setState({ panelMinified: !this.state.panelMinified });
    }

    _generatePanelStatus = () => {
        // Define the icon
        let icon = this.state.panelMinified ? "fa-eye" : "fa-eye-slash";

        return <div>
            <Button className="minified-button color-secondary ml-3" variant="default">
                <i className={"far " + icon}  onClick={this._handleMinifiedPanelClick}></i>
            </Button>
        </div>;
    }

    render() {
        // Generate table status element
        const panelStatus = this._generatePanelStatus();
        // Check if is minified or not
        const minified =  this.state.panelMinified || false;

        return (
            <EPanel className="minifiable-panel" title="Area Chart" subtitle="Visual Data" actionsNode={panelStatus}>
                <div className={"mt-3 " + (minified ? "hidden-plot" : "")}>
                    <EChart type="line" data={this.props.data} options={this.options} {...this.props}/>
                </div>
            </EPanel>
        )
    }
}

export default Plot

import { LOGIN, LOGOUT } from "./actionTypes";

// Export "login" action
export const login = data => ({
    // Define the action type
    type: LOGIN,
    // Define the data to pass with the action
    payload: data
});

// Export "logout" action
export const logout = data => ({
    // Define the action type
    type: LOGOUT,
    // Define the data to pass with the action
    payload: data
});


// Export "callsUpdate" action
export const callsUpdate = data => ({
    // Define the action type
    type: data.type,
    // Define the data to pass with the action
    payload: data
});

import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import { Nav, NavItem, NavLink } from 'reactstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import ENavbar from '../../lib/components/navbar';

import { logout } from '../../redux/actions';
import Utility from '../classes/Utility.js';
import Logger from '../classes/Logger.js';
import Api from '../classes/Api.js';
import History from '../classes/History.js';

import '../styles/Header.css';

const CollapseNav = () => (
    <div className="d-md-flex">
        <Nav className="navbar-nav mr-auto py-3 py-md-0">
            <NavItem>
                <NavLink className="active" href="" onClick={(e) => e.preventDefault()}>Dashboard</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="" onClick={(e) => e.preventDefault()}>Contacts</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="" onClick={(e) => e.preventDefault()}>Info</NavLink>
            </NavItem>
        </Nav>
    </div>
)

class Header extends React.Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        name: 'Header'
    }


    _handleMinifiedClick = (event) => {
        // Toggle minified
        this.props.handleToggleMinified();
    }

    _generateActions = () => {
        // Load user from persist storage
        let user = Utility.loadPersistData('user');
        // If user is defined
        let name = user
            // Take the name, or the username, or email
            ? (user.name || user.username || user.email || "Welcome")
            // Otherwise write welcome
            : "Welcome";
        // Return the dropdown actions
        return (
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  <i className="fa fa-user-circle"></i>
                  <span className="d-none d-md-inline-block">{name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={this._handleClick}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
        );
    }

    _generateBrand = () => {
        //<span>Media Rights <small>rights made simple</small></span>
        return (
            <div className="row e-logo">
                <Button className="minified-button color-secondary" variant="default" onClick={this._handleMinifiedClick}>
                    <i className="fas fa-bars stack-icon "></i>
                </Button>

                <a className="mx-1" href="/">
                    <img className="logo-header" src="/images/logo.png" alt="logo" />
                </a>

            </div>
        );
    }

    /**
     * Component constructor
     * @param {Object} props [Component props]
     */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            value: ''
        }
    }

    /**
     * Handle click in button
     * @return void
     */
    _handleClick = () => {
        // Call get task from API
        Api.logout().then(
            // Manage success
            (response) => {
                // Log in debug the call
                Logger.write('Header@_handleClick -> success.', 0, response);
                // Dispatches action to logout redux
                this.props.logout({'token': null});
                // Redirect to home
                return History.go('/');
            }
        )
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Login@doLogin -> error.', 3, err);
                // Dispatches action to logout redux
                this.props.logout({'token': null});
                // Redirect to home
                return History.go('/');
            }
        );
    }

    /**
     * Render component
     * @return {} []
     */
     render() {
        const actions = this._generateActions();
        const brand = this._generateBrand();
        return (
            <ENavbar
                title="TITLE"
                brandNode={brand}
                collapseNode={<CollapseNav/>}
                actionsNode={actions}
                className={this.props.className}
                navInCenter
            />
        );
    }
}

// Export default component to be accessible in other components
export default withRouter(connect(
  null,
  { logout }
)(Header));

// const el = document.body
// ReactDOM.render(<Header name='John' />, el)

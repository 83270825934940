import React from 'react';

import Button from 'react-bootstrap/Button';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import EPanel from '../../lib/components/panel';

import Config from '../classes/Config';
import Utility from '../classes/Utility';
import Permission from '../classes/Permission';

import '../styles/Table.css';

class Table extends React.Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        name: 'Table'
    }

    /**
     * Component constructor
     * @param Object props    Component props
     */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            perPage: Config.get('DEFAULT_PAGINATION_LIMIT')
                ? parseInt(Config.get('DEFAULT_PAGINATION_LIMIT'))
                : 10,
            panelMinified: false
        }

    }

    /**
     * Handle click on minified/maxified panel
     * @return void
     */
    _handleMinifiedPanelClick = () => {
        // Invert value of panel minified
        this.setState({ panelMinified: !this.state.panelMinified });
    }

    /**
     * Generate the table status span to be insert in table view
     * @return JSX  The tag element
     */
    _generatePanelStatus = () => {
        // Get the total number
        const itemsTotal = this.props.total,
        // Get number of content start in current page
        itemsFrom = (this.state.perPage * (this.props.page - 1)) + 1,
        // Get number of content end in current page
        itemsTo = Math.min(itemsFrom + this.state.perPage - 1, itemsTotal);
        // Define the icon
        let icon = this.state.panelMinified ? "fa-eye" : "fa-eye-slash";

        // Return a span
        return <div>
            <small className="text-muted">{`${itemsFrom} - ${itemsTo} of ${itemsTotal}`}</small>
            <Button className="minified-button color-secondary ml-3" variant="default" onClick={this._handleMinifiedPanelClick}>
                <i className={"far " + icon}  ></i>
            </Button>
        </div>
    }

    /**
     * Generate the table columns
     * @return array    The table columns
     */
    _generateColumns = () => {
        // Init columns
        let columns = [
            {
                dataField: 'id',
                text: '#',
                headerClasses: 'text-center',
                classes: 'text-center',
                sort: true,
                sortCaret: (order, column) => {
                    // Init main class icon
                    let asc = "fas fa-caret-up sort-icon";
                    let desc = "fas fa-caret-down sort-icon";

                    // If is asc, set asc sort selected
                    if (order === "asc") asc = asc + " sort-selected";

                    // If is desc, set desc sort selected
                    if (order === "desc") desc = desc + " sort-selected";

                    // Return the icons with the class
                    return <div className="caret-container">
                        <i className={asc}></i>
                        <i className={desc}></i>
                    </div>
                },
                formatter: (cell, row, index) => (
                    <b>{ row.id }</b>
                )
            },
            {
                dataField: 'string',
                text: 'Name',
                headerClasses: 'max-width',
                classes: 'text-nowrap',
                formatter: (cell, row, index) => (
                    <Button
                        className="show-token-information"
                        variant="link"> { row.string }
                    </Button>
                )
            },
            {
                dataField: 'computed_cost',
                text: 'Cost',
                classes: 'text-center text-nowrap',
                sort: false,
                sortCaret: (order, column) => {
                    // Init main class icon
                    let asc = "fas fa-caret-up sort-icon";
                    let desc = "fas fa-caret-down sort-icon";

                    // If is asc, set asc sort selected
                    if (order === "asc") asc = asc + " sort-selected";

                    // If is desc, set desc sort selected
                    if (order === "desc") desc = desc + " sort-selected";

                    // Return the icons with the class
                    return <div className="caret-container">
                        <i className={asc}></i>
                        <i className={desc}></i>
                    </div>
                },
                formatter: (cell, row, index) => {

                    // If is used by over 100 times, return custom span
                    if (row.count_used_by > 100) return (
                        <span className="badge badge-warning">
                            Over 100 used
                        </span>
                    );

                    // If cell is defined, use it, otherwise use -
                    const val = (cell || parseInt(cell) === 0)
                        ? (cell + "€") : "-";
                    // Return value span
                    return <span>{val}</span>;
                }
            },
            {
                dataField: 'source_repr',
                text: 'Source',
                headerClasses: 'max-width',
                classes: 'text-nowrap',
                formatter: (cell, row, index) => (
                    <div>{ row.source ? <span>{cell}</span> : <span className="badge badge-light">Not consumed</span> }</div>
                )
            }
        ];

        let editor = [{
            dataField: 'editor_repr',
            text: 'Editor',
            headerClasses: 'max-width',
            classes: 'text-nowrap'
        }];

        let mmo = [{
            dataField: 'mmo_repr',
            text: 'MMO',
            headerClasses: 'max-width',
            classes: 'text-nowrap'
        }];

        let timestaps = [{
            dataField: 'created_at',
            text: 'Created At',
            classes: 'text-nowrap',
            sort: true,
            sortCaret: (order, column) => {
                // Init main class icon
                let asc = "fas fa-caret-up sort-icon";
                let desc = "fas fa-caret-down sort-icon";

                // If is asc, set asc sort selected
                if (order === "asc") asc = asc + " sort-selected";

                // If is desc, set desc sort selected
                if (order === "desc") desc = desc + " sort-selected";

                // Return the icons with the class
                return <div className="caret-container">
                    <i className={asc}></i>
                    <i className={desc}></i>
                </div>
            },
            formatter: (cell, row, index) => (
                <span>{ Utility.momentFormat(cell, 'human_short') }</span>
            )
        },
        {
            dataField: 'consumed_at',
            text: 'Consumed At',
            classes: 'text-nowrap',
            sort: true,
            sortCaret: (order, column) => {
                // Init main class icon
                let asc = "fas fa-caret-up sort-icon";
                let desc = "fas fa-caret-down sort-icon";

                // If is asc, set asc sort selected
                if (order === "asc") asc = asc + " sort-selected";

                // If is desc, set desc sort selected
                if (order === "desc") desc = desc + " sort-selected";

                // Return the icons with the class
                return <div className="caret-container">
                    <i className={asc}></i>
                    <i className={desc}></i>
                </div>
            },
            formatter: (cell, row, index) => (
                <div>{ cell ? <span>{Utility.momentFormat(cell, 'human_short')}</span> : <span className="badge badge-light">Not consumed</span> }</div>
            )
        }];

        // If user is editor, attach editor column, otherwise take columns
        columns = Permission.isEditor() ? [...columns, ...editor] : columns;
        // If user is mmo, attach mmo column, otherwise take columns
        columns = Permission.isMMO() ? [...columns, ...mmo] : columns;
        // Attach timestamps columns
        columns = [...columns, ...timestaps]
        // Return columns
        return columns;
    }

    /**
     * Render component
     * @return {} []
     */
    render() {
        const tableRowEvents = {
            onClick: (e, row, rowIndex) => {
                // Prevent chrome to scroll top
                e.preventDefault();
                // Take target
                const target = e.target;

                // If is not a click on the right cell
                if (!target.classList.contains('show-token-information'))
                    // Exit
                    return false;

                // Open metadata modal
                this.props.handleMetadataModelOpen(row);
            }
        }
        // Take page
        let page = this.props.page;
        // Take per page
        let perPage = this.state.perPage;
        // Take total
        let total = this.props.total;
        // Generate table status element
        const panelStatus = this._generatePanelStatus();
        // Generate columns
        const columns = this._generateColumns();
        // Check if is minified or not
        const minified =  this.state.panelMinified || false;

        // Return table
        return (
            <EPanel className="minifiable-panel" title="Tokens" subtitle="List of tokens" actionsNode={panelStatus}>
                <div className={"mt-3 dashboard-table-container " + (minified ? "hidden-plot" : "")}>
                    <BootstrapTable
                        remote={{
                            filter: true,
                            sort: true,
                            pagination: true,
                        }}
                        keyField='id'
                        data={ this.props.items }
                        columns={ columns }
                        pagination={ paginationFactory({
                            page: page,
                            sizePerPage: perPage,
                            totalSize: total,
                            hideSizePerPage: true
                        }) }
                        onTableChange={ this.props.handleTableChange }
                        rowEvents={ tableRowEvents } />
                </div>
            </EPanel>
        );
    }
}

// Export default component to be accessible in other components
export default Table;

// const el = document.body
// ReactDOM.render(<Table name='John' />, el)

import Config from './Config.js';

const Logger = {
    /**
     * If is enabled print all logs within the level.
     * @type boolean
     */
    enabled: function() {
        // Return, if debug_enabled is defined in config
        return Config.get('DEBUG_ENABLED')
            // Check if is (int) 1 and return true, false otherwise
            ? (parseInt(Config.get('DEBUG_ENABLED')) === 1 ? true : false)
            // If not defined, return false
            : false;
    },

    /**
     * The level of log from what to be print
     * // TODO: load this value from a config file
     * 0 => Verbose
     * 1 => Info
     * 2 => Warnings
     * 3 => Error
     * @type int
     */
    level: 0,

    /**
     * Write a log inside console.
     * Check if logger is enabled and if the level is setted
     * @param  String  message  The message to be printed.
     * @param  Integer level    The level of log.
     * @param  Multy   data     The optional data to be printed.
     * @return Boolean          True if log is printed in console.
     */
    write: function(message, level, data) {
        // If level is defined take it, otherwise set 0 as default
        level = level ? level : 0;

        // If we have an error, and some data is provided
        if (data && level === 3) {
            // Compose error as string
            data = this._composeHumanReadableError(data);
        }

        // If is not enabled OR the level to be printed is lower then settings.
        if (!this.enabled() || level < this.level) {
            // Return false
            return false;
        }

        // Switch on level log
        switch (level) {
            // If is verbose
            case 0:
                // If data is defined
                data
                // Log as verbose level with data printed
                ? console.debug(message, data)
                // Log as verbose level
                : console.debug(message);
                break;
            // If is log
            case 1:
                // If data is defined
                data
                // Log as log level with data printed
                ? console.log(message, data)
                // Log as log level
                : console.log(message);
                break;
            // If is warning
            case 2:
                // If data is defined
                data
                // Log as warning level with data printed
                ? console.warn(message, data)
                // Log as warning level
                : console.warn(message);
                break;
            // If is error
            case 3:
                // If data is defined
                data
                // Log as error level with data printed
                ? console.error(message, data)
                // Log as error level
                : console.error(message);
                break;
            // Print verbose as default
            default:
                // If data is defined
                data
                // Log as verbose level with data printed
                ? console.verbose(message, data)
                // Log as verbose level
                : console.verbose(message);
                break;
        }

        // Return true
        return true;
    },

    /**
     * Compose a string message to be logged.
     * This will reported in Sentry.
     * @param  XHRError err The error object OR some data to log
     * @return string       The error string
     */
    _composeHumanReadableError: function(err) {
        // Init human readable error
        let hrError = "";

        // If has not response, is not an xhr error, only some data to log
        if (!err.hasOwnProperty('response')) return err;

        // Get response
        let response = err.response;

        // If has not status or statusText or data
        if (!response
            || !response.hasOwnProperty('status')
            || !response.hasOwnProperty('statusText')
            || !response.hasOwnProperty('data')
        // ... maybe is not an xhr error, only some data to log
        ) return err;

        // Compose error
        hrError = response.status + ": "
            + response.statusText + ". With data: "
            + JSON.stringify(response.data);

        // Return composed message
        return hrError;
    }

}

export default Logger

import { LOGIN } from "../actionTypes";

import Utility from "../../components/classes/Utility";

// Create the initial state of the
const initialState = {
    // The data if the view changing
    token: localStorage.getItem('token') ?  localStorage.getItem('token') : ""
}

// Export the reducer "viewChange", taking the state and the action
export default function (state = initialState, action) {
    // Create a copy of current state
    let newState = {...state};

    // Switch on action type
    switch (action.type) {
        // If the action is LOGIN
        case LOGIN: {
            // Get token
            // NOTE: AVOID NULL: will be saved as "null"
            let token = action.payload.token ? action.payload.token : "";
            // NOTE: AVOID NULL: will be saved as "null"
            let user = action.payload.user ? action.payload.user : "";
            // Set token in local storage
            localStorage.setItem('token', token);
            // Set user in local storage
            Utility.savePersistData('user', user);
            // Take the new data passed, and set it into the state
            newState.token = token;
            // Return the updated state
            return newState;
        }
        // As default
        default:
            // Return the default state
            return state;
    }
}

import { REQUEST_START, REQUEST_END } from "../actionTypes";

// Create the initial state of the
const initialState = {
    calls: [],
    started: [],
    ended: [],
}

// Export the reducer "requestStart", taking the state and the action
export default function (state = initialState, action) {
    // Create a copy of current state
    let newState = {...state};

    // Switch on action type
    switch (action.type) {
        // If the action is REQUEST_START
        case REQUEST_START: {
            // Take the new data passed, and set it into the state
            newState.calls.push(action.payload);
            // Take the new data passed, and set it into the state
            newState.started.push(action.payload);
            // Return the updated state
            return newState;
        }
        // If the action is REQUEST_END
        case REQUEST_END: {
            // Filter all stored call except the passed one
            let calls = newState.calls.filter(
                call => call.uuid !== action.payload.uuid
            );
            // Take the new data passed, and set it into the state
            newState.calls = calls;
            // Push the current call inside ended var
            newState.ended.push(action.payload);
            // Return the updated state
            return newState;
        }
        // As default
        default:
            // Return the default state
            return state;
    }
}

import Utility from './Utility.js';
import Config from './Config.js';

/**
 * Permission
 * @type {Object}
 */
const Permission = {

    /**
     * Load authenticated user
     * @type {[type]}
     */
    user: Utility.loadPersistData('user'),

    /**
     * Check if user is a moderator
     * @return boolean  True if is a moderator
     */
    isMediaRightsUser: function() {
        // If user is not authenticated, return false
        if (!this.authenticated()) return false;
        // Load id for a MMO type user and avoid string load
        const id = parseInt(Config.get('DEFAULT_USER_TYPE_MEDIA_RIGHTS', -1));

        // If user type is defined
        return (this.user.user_type
            // And type is correct type loaded, return true
            && this.user.user_type === id)
            // Otherwise return false
            || false;
    },

    /**
     * Check if user is a admin memeber
     * @return boolean  True if is a admin memeber
     */
    isAdmin: function() {
        // If user is not authenticated, return false
        if (!this.authenticated()) return false;
        // If user is staff, is almost anyting
        if (this.isMediaRightsUser()) return true;

        // Load id for a admin type user and avoid string load
        const id = parseInt(Config.get('DEFAULT_USER_TYPE_ADMIN', -1));
        // If user type is defined
        return (this.user.user_type
            // And type is correct type loaded, return true
            && this.user.user_type === id)
            // Otherwise return false
            || false;
    },

    /**
     * Check if user is a mmo+editor memeber
     * @return boolean  True if is a mmo+editor memeber
     */
    isMMOEditor: function() {
        // If user is not authenticated, return false
        if (!this.authenticated()) return false;
        // If user is admin, is is true!
        if (this.isAdmin()) return true;

        // Load id for a MMO+Editor type user and avoid string load
        const id = parseInt(Config.get('DEFAULT_USER_TYPE_MMO_EDITOR', -1));
        // If user type is defined
        return (this.user.user_type
            // And type is correct type loaded, return true
            && this.user.user_type === id)
            // Otherwise return false
            || false;
    },

    /**
     * Check if user is a mmo memeber
     * @return boolean  True if is a mmo memeber
     */
    isMMO: function() {
        // If user is not authenticated, return false
        if (!this.authenticated()) return false;
        // If user is MMO+Editor, is true!
        if (this.isMMOEditor()) return true;

        // Load id for a MMO type user and avoid string load
        const id = parseInt(Config.get('DEFAULT_USER_TYPE_MMO', -1));
        // If user type is defined
        return (this.user.user_type
            // And type is correct type loaded, return true
            && this.user.user_type === id)
            // Otherwise return false
            || false;
    },

    /**
     * Check if user is a editor memeber
     * @return boolean  True if is a editor memeber
     */
    isEditor: function() {
        // If user is not authenticated, return false
        if (!this.authenticated()) return false;
        // If user is MMO+Editor, is true!
        if (this.isMMOEditor()) return true;

        // Load id for a editor type user and avoid string load
        const id = parseInt(Config.get('DEFAULT_USER_TYPE_EDITOR', -1));
        // If user type is defined
        return (this.user.user_type
            // And type is correct type loaded, return true
            && this.user.user_type === id)
            // Otherwise return false
            || false;
    },

    /**
     * Return if user is authenticated or not
     * @return object   User JS object if authenticated, false otherwise
     */
    authenticated: function() {
        // If user is found, return it otherwise return false
        return this.user || false;
    }
}

export default Permission;

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from "./redux/store";
import Config from './components/classes/Config';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

if (Config.get('sentry_dsn', '')) {
    Sentry.init({
    environment: Config.get('env', 'local'),
    dsn: Config.get('sentry_dsn', '')
    });
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
